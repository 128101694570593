/* =================================herosetion================================================== */
.blurred-video {
      filter: blur(1px); /* Adjust the blur radius (5px) to your liking */
      -webkit-filter: blur(1px); /* For better compatibility with older browsers */
    }
    /* Responsive container */
/* Responsive container */
.responsive-container {
      font-family: "Poppins", sans-serif;
      font-weight: bold;
      color: blue;
      display: flex;
      justify-content: flex-start;
      align-items: center;
      gap: 0.5rem; /* Space between text and animation */
      flex-wrap: wrap; /* Wraps text on smaller screens */
    }
    
    /* Container for the static text */
    .static-text-container {
      display: inline-block; /* Keeps the static text fixed in place */
    }
    
    /* Container for the typing animation text */
    .typing-text-container {
      display: inline-block; /* Keeps the typing animation text inline */
      color: gold; /* Apply the desired color to the typing text */
    }
    
    /* Responsive text styling */
    .static-text {
      font-size: 1.8rem; /* Adjust as needed */
    }
    
    @media (max-width: 768px) {
      .static-text {
        font-size: 1.5rem; /* Smaller font size on smaller screens */
      }
    }
    
    @media (max-width: 576px) {
      .static-text {
        font-size: 1.2rem; /* Even smaller font size on very small screens */
      }
    }
    

    /* Style for the navbar links */
.navbar-nav .nav-link {
      position: relative; /* Ensure the pseudo-element is positioned relative to the link */
      padding-bottom: 5px; /* Add space for the underline */
    }
    
    /* Underline effect on hover */
    .navbar-nav .nav-link::after {
      content: ''; /* Empty content */
      position: absolute;
      left: 0;
      bottom: 0;
      width: 0;
      height: 2px; /* Thickness of the underline */
      background-color:#fb7b1c; /* Color of the underline */
      transition: width 0.3s ease; /* Smooth transition for the underline effect */
    }
    
    /* Expand the underline on hover */
    .navbar-nav .nav-link:hover::after {
      width: 100%; /* Full width of the link */
    }
    






    .scroll-container {
      width: 100%;
      display: flex;
      overflow: hidden; /* Hide horizontal scrollbars */
      white-space: nowrap; /* Prevent wrapping */
      padding: 10px 0;
      background-color: #ebebeb;
      border-radius: 10px;
      position: relative;
    }
    
    .scroll-item {
      display: inline-block;
      width: 150px; /* Fixed width */
      height: 200px; /* Fixed height for uniformity */
      margin-right: 15px;
      text-align: center;
      background-color: #ffffff;
      border-radius: 5px;
      padding: 10px;
      box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
    }
    
    .scroll-item img {
      width: 100%; /* Responsive */
      height: 120px; /* Fixed height */
      object-fit: contain; /* Preserve aspect ratio */
    }
    
/* ====================================slider========================= */
.carousel-container {
  margin: 10% auto;
  background-color: white;
  border-radius: 0px;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden; /* Ensures content doesn't overflow */
}

.carousel-card {
  background-color: white;
  border-radius: 0px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  text-align: center;
  padding: 5px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 150px;
  margin: 0 5px;
  height: 130px;
}

.carousel-card img {
  width: 80px;
  height: 70px;
  object-fit: contain;
  margin-bottom: 10px;
}

@media (max-width: 1200px) {
  .carousel-container {
    width: 80%;
  }
}

@media (max-width: 992px) {
  .carousel-container {
    margin-top:"5%";
    width: 70%;
  }
}

@media (max-width: 768px) {
  .carousel-container {
    margin-top:"5%";
    width: 60%;
  }
}

@media (max-width: 576px) {
  .carousel-container {
    margin-top:"7%";
    width: 50%;
  }
}

@media (max-width: 400px) {
  .carousel-container {
    margin-top:"5%";
    width: 100%;
  }
}
/* =========================test============================== */
.text-container {
  display: flex; /* Use flexbox to center content */
  justify-content: center; /* Center horizontally */
  align-items: center; /* Center vertically if needed */
  text-align: center; /* Center text within the container */
  margin-top: 3%; /* Space above the container */
}

.text-heading {
  color: #fb7b1c; /* Text color */
  font-size: 2rem; /* Font size */
  /* Optional: Add responsive font size for better mobile experience */
}

/* Responsive styles */
@media (max-width: 1200px) {
  .text-heading {
    font-size: 1.8rem; /* Slightly smaller font size for larger screens */
  }
}

@media (max-width: 992px) {
  .text-heading {
    font-size: 1.6rem; /* Smaller font size for medium screens */
  }
}

@media (max-width: 768px) {
  .text-heading {
    font-size: 1.4rem; /* Smaller font size for tablets */
  }
}

@media (max-width: 576px) {
  .text-heading {
    font-size: 1.2rem; /* Smaller font size for mobile phones */
  }
}
