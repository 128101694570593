/* Ensure the Swiper container takes full width and height */
.swiper {
  width: 100%;
  height: 400px; /* Set the height you want for the images */
}

/* Ensure that all slides are the same size */
.swiper-slide {
  text-align: center;
  font-size: 18px;
  background: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
}

/* Image styles */
.swiper-slide img {
  width: 100%;
  height: 100%;
  object-fit: cover; /* Ensures the image covers the container while maintaining aspect ratio */
}

/* You can adjust the height as per your requirements */
.swiper-slide-image {
  width: 100%;
  height: 100%; /* Ensure all images take the full height of the slide */
  object-fit: cover; /* Maintain aspect ratio while covering the entire slide */
}
