.categories-container {
      position: relative;
      width: 100%;
      min-height: 100%;
      background-image: url('/public/categores.jpg'); /* Replace with your background image path */
      background-size:cover;
      background-position: top left; /* Position the image at the top left */
      background-repeat: no-repeat; /* Prevent the background image from repeating */
      border-radius: 10px;
}
    
    .overlay {
      top: 0;
      left: 0;
      width: 100%;
      min-height: 100%;
      background-color: hsla(26, 97%, 55%, 0.548); /* Semi-transparent overlay color */
      display: flex;
      flex-direction: column;
      padding: 20px; /* Add padding if needed */
      color: white;
      border-radius: 10px;
    }
    
/* Container for the remaining categories */
.category-row {
      display: grid;
      grid-template-columns: repeat(auto-fit, minmax(250px, 1fr)); /* Responsive grid layout */
      gap: 1rem;
    }
    
    /* Styling for the category items in the row */
    .category-item-row {
      background-color: white;
      border-radius: 8px;
      box-shadow: 0 4px 8px rgba(0, 0, 0, 1);
      padding: 10px;
      display: flex;
      justify-content: space-between; /* Align child divs to the left and right */
      align-items: center;
      overflow: hidden;
      cursor: pointer;
    }


    .title {
      font-size: 2rem;
      margin-bottom: 1rem;
    }
    
    .categories-list {
      display: grid;
      grid-template-columns: repeat(auto-fit, minmax(250px, 1fr)); /* Responsive grid layout */
      gap: 1rem;
    }
    
    .category-item {
      background-color: white;
      border-radius: 8px;
      box-shadow: 0 4px 8px rgba(0, 0, 0, 1);
      padding: 10px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      overflow: hidden;
      cursor: pointer;
      transition: transform 0.3s ease-in-out; /* Add transition for smooth effect */
    }
    .category-info1 {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
     
    }
    .category-item:hover .category-icon {
      transform: scaleX(-1); /* Flip the image horizontally */
    }
    
    /* Optional: You can add a hover effect for the entire category card */
    .category-item:hover {
      transform: scale(1.05); /* Slightly enlarge the card on hover */
    }

 .category-name1 {
      font-size: 1.2rem; /* Slightly larger text */
      color: black;
      font-family: "Poppins";
      margin-bottom: 5px; /* Space between name and icon */
    }

    .category-info {
     
      border-radius: 8px;
      /* box-shadow: 0 4px 8px rgba(0, 0, 0, 1); */
      padding:5px;
      display: flex;
      /* justify-content: space-between; Align child divs to the left and right */
      align-items: center;
      /* overflow: hidden; */

    }
    
   
    .category-name {
      font-size: 1.2rem; /* Slightly larger text */
      color: black;
      display: flex;
      font-family: "Poppins";
      margin-bottom: 5px; /* Space between name and icon */
    }
    .category-icon {
      width: 60px; /* Adjust icon size as needed */
      height: 60px;
      transition: transform 0.3s ease-in-out; /* Add transition for smooth effect */
    }
    
    .arrow-container {
      background-color: #fb7b1c; /* Background color for the arrow */
      border-radius: 50%;
      padding: 5px;
      display: flex;
      align-items: center;
      justify-content: center;
      margin-top: 10px; /* Space above the arrow */
    }
    
    .arrow-icon {
      font-size: 1.2rem; /* Adjust arrow size */
      color: white; /* Arrow color */
    }
    
    .cart-container {
      display: flex;
      align-items: center;
      justify-content: center;
    }
    
    @media (max-width: 1200px) {
      .categories-list {
        grid-template-columns: repeat(3, 1fr); /* Show 3 items per row on medium screens */
      }
    }
    
    @media (max-width: 900px) {
      .categories-list {
        grid-template-columns: repeat(2, 1fr); /* Show 2 items per row on small screens */
      }
    }
    
    @media (max-width: 600px) {
      .categories-list {
        grid-template-columns: 1fr; /* Show 1 item per row on extra small screens */
      }
    }
    